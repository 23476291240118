import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LeasingComponent} from './modules/leasing/leasing.component';
import {CalculationsComponent} from './modules/calculations/calculations.component';
import {CreditComponent} from './modules/credit/credit.component';
import {LoanComponent} from './modules/loan/loan.component';
import {AuthComponent} from './modules/auth/auth.component';
import {DashboardComponent} from './modules/dashboard/dashboard.component';
import {AuthGuard} from './modules/auth/guards/auth.guard';


const routes: Routes = [
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'leasing', component: LeasingComponent},
  {path: 'credit', component: CreditComponent},
  {path: 'loan', component: LoanComponent},
  {path: 'calculations', component: CalculationsComponent},
  {path: 'auth', component: AuthComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
