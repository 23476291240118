<div class="container-fluid">
  <h2 class="my-2">
    {{'summary' | translate}}
  </h2>
  <div class="row">
    <div class="col-12">
      <table mat-table [dataSource]="summary" class="w-100">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{'type' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{element.type | translate}}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>{{'value' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{element.value}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="col-lg-12 d-flex justify-content-end my-3">
      <button type="button" (click)="showSchedule()" color="primary" mat-raised-button>{{'show schedule' | translate}}</button>
    </div>
  </div>
</div>
