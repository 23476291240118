<app-calculations-creator-header title="{{id ? 'edit eco' : 'create eco' | translate}}"></app-calculations-creator-header>

<section class="container-fluid">
  <div class="row">
    <div class="col-lg-6 p-0">
      <mat-horizontal-stepper [@.disabled]="true" class="position-relative" labelPosition="bottom" [linear]="true" #stepper>
        <mat-step [stepControl]="step1">
          <ng-template matStepLabel>{{'calculation' | translate}}</ng-template>
          <app-loan-creator-step-1 [formGroup]="step1"></app-loan-creator-step-1>
        </mat-step>
        <mat-step [stepControl]="step2">
          <ng-template matStepLabel>{{'client' | translate}}</ng-template>
          <app-calculations-creator-step-2 [formGroup]="step2"></app-calculations-creator-step-2>
        </mat-step>
        <mat-step [stepControl]="step3">
          <ng-template matStepLabel>{{'send' | translate}}</ng-template>
          <app-calculations-creator-step-3 [formGroup]="step3"></app-calculations-creator-step-3>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="col-lg-6">
      <app-loan-creator-calculation [step1]="step1"></app-loan-creator-calculation>
    </div>
  </div>
</section>

<app-calculations-creator-footer>
  <button [disabled]="step1.invalid"  class="mr-2" (click)="createPdfCalculation()" mat-raised-button color="primary">PDF</button>
  <button [disabled]="step1.invalid" class="mr-2" *ngIf="id" (click)="editCalculation()" mat-raised-button color="primary">{{'edit' | translate}}</button>
  <button [disabled]="step1.invalid"  (click)="saveCalculation()" mat-raised-button color="primary">{{ (id ? 'duplicate' : 'save') | translate}}</button>
  <mat-divider class="mx-4" [vertical]="true"></mat-divider>
  <button *ngIf="stepper.selectedIndex !== 0" mat-raised-button color="primary" class="mr-2" (click)="goBack(stepper)">{{'back' | translate}}</button>
  <button *ngIf="stepper.selectedIndex+1 !== stepper?._steps?.length" mat-raised-button color="primary" (click)="goNext(stepper)">{{'next' | translate}}</button>
  <button [disabled]="step1.invalid || step3.invalid" *ngIf="!id && stepper.selectedIndex+1 === stepper?._steps?.length" mat-raised-button color="primary" (click)="saveCalculation(true)">{{'save and send' | translate}}</button>
  <button [disabled]="step1.invalid || step3.invalid" *ngIf="id && stepper.selectedIndex+1 === stepper?._steps?.length" mat-raised-button color="primary" (click)="editCalculation(true)">{{'edit and send' | translate}}</button>
</app-calculations-creator-footer>
