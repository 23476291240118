<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container-fluid py-3">
    <div class="row">
      <div class="col-lg-12">
        <h2>{{'client creator' | translate}}</h2>
      </div>
      <div class="col-md-4 py-2">
        <app-clients-creator-field-nip></app-clients-creator-field-nip>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'company_name' | translate}}*</mat-label>
          <input matInput type="string" placeholder="Nazwa firmy" formControlName="company_name">
          <mat-error><app-field-errors [errors]="form.get('company_name').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'accountancy_type' | translate}}</mat-label>
          <input matInput type="string" placeholder="forma księgowości" formControlName="accountancy_type">
          <mat-error><app-field-errors [errors]="form.get('accountancy_type').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'accountant_phone' | translate}}*</mat-label>
          <input matInput type="string" placeholder="forma księgowości" formControlName="accountant_phone">
          <mat-error><app-field-errors [errors]="form.get('accountant_phone').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'accountant_email' | translate}}*</mat-label>
          <input matInput type="string" placeholder="accountant_email" formControlName="accountant_email">
          <mat-error><app-field-errors [errors]="form.get('accountant_email').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'company_email' | translate}}*</mat-label>
          <input matInput type="string" placeholder="jankowalski@firma.pl" formControlName="company_email" >
          <mat-error><app-field-errors [errors]="form.get('company_email').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'company_phone' | translate}}*</mat-label>
          <input matInput type="string" placeholder="888888888" formControlName="company_phone" >
          <mat-error><app-field-errors [errors]="form.get('company_phone').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-10 py-2">
        <mat-form-field class="w-100">
          <mat-label>{{'additional_information' | translate}}</mat-label>
          <textarea formControlName="notes" matInput placeholder="..."></textarea>
          <mat-error><app-field-errors [errors]="form.get('notes').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 py-2">
        <mat-form-field appearance="fill">
          <mat-label>{{'voivodeship' | translate}}</mat-label>
          <mat-select formControlName="voivodeship">
            <mat-option *ngFor="let elem of voivodeships" [value]="elem">
              {{'voivodeships.' + elem | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12 py-2">
        <mat-checkbox color="primary" formControlName="agreement">{{'the contract signed' | translate}}</mat-checkbox>
      </div>
      <div class="col-lg-12 d-flex justify-content-end">
        <button type="submit" color="primary" mat-raised-button>{{'save' | translate}}</button>
      </div>
    </div>
  </div>
</form>
