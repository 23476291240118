import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../../material.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
  ]
})
export class DashboardModule {
}
