import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective } from '@angular/forms';
import { periods, buyouts } from '../../../../../../assets/helpers/CONSTS';

@Component({
  selector: 'app-leasing-creator-step-1',
  templateUrl: './leasing-creator-step-1.component.html',
  styleUrls: ['./leasing-creator-step-1.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class LeasingCreatorStep1Component implements OnInit {
  periods = periods;
  buyouts = buyouts;

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.parent.form.addControl('calculation', this.fb.group({
      title: ['Przykładowy tytuł' , {validators: [Validators.required], updateOn: 'blur'}],
      description: ['To mój opis' , {validators: [Validators.required], updateOn: 'blur'}],
      initial_fee: [20, {validators: [Validators.required, Validators.min(1), Validators.max(45)], updateOn: 'blur'}],
      period: [48, {validators: [Validators.required], updateOn: 'blur'}],
      amount: [20000, {validators: [Validators.required, Validators.min(10000), Validators.max(100000000)], updateOn: 'blur'}],
      buyout: [{ value : 25, disabled: true}, {updateOn: 'blur'}],
      insurance: [true, {validators: [Validators.requiredTrue], updateOn: 'blur'}],
      margin: [0.01 , {validators: [Validators.required, Validators.min(0), Validators.max(100)], updateOn: 'blur'}],
      wibor: [0.01 , {validators: [Validators.required, Validators.min(0), Validators.max(100)], updateOn: 'blur'}],
    }));

    this.parent.form.get('calculation.period').valueChanges.subscribe(value => {
      const index = this.periods.indexOf(value);
      const selectedBuyout = this.buyouts[index];

      this.parent.form.get('calculation').patchValue({buyout: selectedBuyout});
    });
  }
}
