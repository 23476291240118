import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
        })
      export class AuthLoginComponent implements OnInit {
        form: FormGroup;

        constructor(
          private fb: FormBuilder,
          private authService: AuthService,
          private router: Router,
          private toastrService: ToastrService
        ) {
        }

        ngOnInit(): void {
          this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
          });
        }

        onSubmit(): void {
          if (this.form.valid) {
            const body = this.form.getRawValue();

            this.authService.login(body).subscribe(
              (response: any) => {
                this.router.navigateByUrl('/dashboard');
                this.toastrService.success('Sukces');
        },
        (err) => {
          this.toastrService.error('Błąd wewnętrzny');
        }
      );
    }
  }
}
