import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldErrorsComponent } from './components/field-errors/field-errors.component';
import {MaterialModule} from '../../material.module';
import {TranslateModule} from '@ngx-translate/core';



@NgModule({
  declarations: [FieldErrorsComponent],
  exports: [
    FieldErrorsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule
  ]
})
export class SharedModule { }
