import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Client} from '../../interfaces/Client';

@Component({
  selector: 'app-clients-detail',
  templateUrl: './clients-detail.component.html',
  styleUrls: ['./clients-detail.component.scss']
})
export class ClientsDetailComponent implements OnInit {
  client: Client;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Client
  ) { }

  ngOnInit(): void {
    const { calculations, ...rest } = this.data;
    this.client = rest;
  }
}
