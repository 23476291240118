import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import {AuthRegisterComponent} from './components/auth-register/auth-register.component';
import {AuthLoginComponent} from './components/auth-login/auth-login.component';
import {AuthProfileComponent} from './components/auth-profile/auth-profile.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../../material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './guards/auth.guard';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import { AuthGuardDirective } from './directives/auth-guard.directive';

@NgModule({
  declarations: [AuthComponent, AuthRegisterComponent, AuthLoginComponent, AuthProfileComponent, AuthGuardDirective],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: AuthComponent,
        children: [
          {
            path: 'auth/login',
            component: AuthLoginComponent
          },
          {
            path: 'auth/register',
            component: AuthRegisterComponent
          },
          {
            path: 'auth/profile',
            component: AuthProfileComponent,
            canActivate: [AuthGuard]
          },
        ]
      }
    ]),
    TranslateModule,
    SharedModule,
  ]
})
export class AuthModule {
}
