import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClientsService} from '../../services/clients.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {Client} from '../../interfaces/Client';
import {forkJoin, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ClientsCreatorComponent} from '../clients-creator/clients-creator.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientsDetailComponent} from '../clients-detail/clients-detail.component';
import {ToastrService} from 'ngx-toastr';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['select', 'company_name', 'company_email', 'company_phone', 'actions'];
  clients = new MatTableDataSource<Client[]>([]);
  listMeta: PageEvent = {
    length: 0,
    pageSize: 0,
    pageIndex: 0
  };
  loading = false;
  selected = new SelectionModel<Client>(true, []);
  private unsubscribe = new Subject();

  constructor(
    private clientsService: ClientsService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getClients();
  }

  selectRow($event, row): any {
    return $event ? this.selected.toggle(row) : null;
  }

  handlePaginatorChanges(event): void {
    this.listMeta = event;
    this.getClients();
  }

  deleteClients(): void {
    const ids = this.selected.selected.map(element => element.id);

    forkJoin(
      ...ids.map((id) => this.clientsService.deleteClient(id))
    )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.toastrService.success(`Usunięto elementów: ${ids.length}`);
          this.getClients();
        },
        (err) => this.toastrService.error('Błąd wewnętrzny')
      );
  }

  showClientDetail(client: Client): void {
    this.dialog.open(ClientsDetailComponent, {
      data: client,
      minWidth: 600
    });
  }

  getClients(): void {
    this.loading = true;
    this.clientsService.getClients().subscribe(
      (response: any) => {
        this.loading = false;
        this.clients = response.data;
      },
      (err) => console.log(err)
    );
  }

  createClient(): void {
    const dialogRef = this.dialog.open(ClientsCreatorComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.getClients();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
