<ng-container formGroupName="calculation">
  <h2 class="my-2">
    {{'calculation' | translate}}
  </h2>
  <div class="container-fluid p-0 m-0">
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'voivodeship' | translate}}</mat-label>
          <mat-select formControlName="voivodeship">
            <mat-option *ngFor="let voivodeship of voivodeships" [value]="voivodeship">
              {{'voivodeships.' + voivodeship | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'title' | translate}}</mat-label>
          <mat-select formControlName="title">
            <mat-option *ngFor="let title of titles" [value]="title">
              {{title | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'amount' | translate}}</mat-label>
          <input matInput type="number" placeholder="amount" formControlName="amount">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.amount').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'commission_for_start' | translate}}</mat-label>
          <input matInput type="number" placeholder="20" formControlName="commission_for_start_percent">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.commission_for_start_percent').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'commission_for_brokerage' | translate}}</mat-label>
          <input matInput type="number" placeholder="20" formControlName="commission_brokerage_percent">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.commission_brokerage_percent').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'period' | translate}}</mat-label>
          <mat-select formControlName="period">
            <mat-option *ngFor="let period of periods" [value]="period">
              {{period}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'installment type' | translate}}</mat-label>
          <mat-select disabled="true" formControlName="installment_type">
            <mat-option *ngFor="let type of installmentTypes" [value]="type">
              {{type | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'margin' | translate}}</mat-label>
          <mat-select formControlName="margin">
            <mat-option *ngFor="let margin of margins" [value]="margin">
              {{margin}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'wibor' | translate}}</mat-label>
          <input disabled="true" matInput type="number" placeholder="wibor" formControlName="wibor">
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'description' | translate}}</mat-label>
          <textarea matInput type="number" formControlName="description"></textarea>
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.description').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>

