import { Component, OnInit } from '@angular/core';
import {ControlContainer, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {documents, templates} from '../../../../../../assets/helpers/CONSTS';

@Component({
  selector: 'app-calculations-creator-step-3',
  templateUrl: './calculations-creator-step-3.component.html',
  styleUrls: ['./calculations-creator-step-3.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CalculationsCreatorStep3Component implements OnInit {
  form: FormGroup;
  templates = templates;
  documents = documents;
  emails: FormArray;

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.form = this.parent.form;

    this.form.addControl('send', this.fb.group({
      document: [this.documents[0]],
      template: [this.templates[0]],
      realizationSend: [false],
      emails: this.fb.array([['przykladowy1@email.com', [Validators.required, Validators.email]]], Validators.required),
    }));

    this.emails = this.form.controls.send.get('emails') as FormArray;
  }

  createContact(): void {
    this.emails.push(new FormControl('przykladowy@email.com', [Validators.required, Validators.email]));
  }

  removeContact(i: number): void {
    this.emails.removeAt(i);
  }
}
