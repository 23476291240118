import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { NavbarThemePickerComponent } from './navbar-toolbar/navbar-theme-picker/navbar-theme-picker.component';
import { NavbarDrawerListComponent } from './navbar-drawer-list/navbar-drawer-list.component';
import { MaterialModule } from 'src/app/material.module';
import { NavbarCalculationPickerComponent } from './navbar-calculation-picker/navbar-calculation-picker.component';
import { NavbarToolbarComponent } from './navbar-toolbar/navbar-toolbar.component';
import { NavbarAuthMenuComponent } from './navbar-toolbar/navbar-auth-menu/navbar-auth-menu.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [NavbarComponent, NavbarThemePickerComponent, NavbarDrawerListComponent, NavbarCalculationPickerComponent, NavbarToolbarComponent, NavbarAuthMenuComponent],
  // providers: [ConfigService],
  exports: [NavbarComponent]
})
export class NavbarModule { }
