<ng-container formGroupName="calculation">
  <h2 class="my-2">
    {{'calculation' | translate}}
  </h2>
  <div class="container-fluid p-0 m-0">
    <div class="row">
      <div class="col-lg-12">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'title' | translate}}</mat-label>
          <input matInput placeholder="Nazwa kalkulacji" formControlName="title">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.title').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'initial_fee' | translate}}</mat-label>
          <input matInput type="number" placeholder="20" formControlName="initial_fee">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.initial_fee').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'amount' | translate}}</mat-label>
          <input matInput type="number" placeholder="amount" formControlName="amount">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.amount').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'period' | translate}}</mat-label>
          <mat-select formControlName="period">
            <mat-option *ngFor="let period of periods" [value]="period">
              {{period}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'buyout' | translate}}</mat-label>
          <mat-select formControlName="buyout">
            <mat-option *ngFor="let buyout of buyouts" [value]="buyout">
              {{buyout}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'margin' | translate}}</mat-label>
          <input matInput type="number" placeholder="margin" formControlName="margin">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.margin').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'wibor' | translate}}</mat-label>
          <input matInput type="number" placeholder="wibor" formControlName="wibor">
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.wibor').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'description' | translate}}</mat-label>
          <textarea matInput type="number" placeholder="description" formControlName="description"></textarea>
          <mat-error><app-field-errors [errors]="parent.form.get('calculation.description').errors"></app-field-errors></mat-error>
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <div class="w-100 my-3 d-flex align-items-end">
          <mat-checkbox
            color="primary"
            formControlName="insurance">
            {{'insurance' | translate}}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
</ng-container>
