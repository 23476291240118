<h2 mat-dialog-title>{{'company_name' | translate}}: {{client.company_name}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="py-3">
  <mat-list>
    <ng-container  *ngFor="let item of client | keyvalue">
      <mat-list-item *ngIf="item.value" class="d-flex justify-content-between">
        {{item.key | translate}}
        &nbsp;
        {{item.value}}
      </mat-list-item>
    </ng-container>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'close' | translate}}</button>
</mat-dialog-actions>
