import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroupDirective } from '@angular/forms';
import { ClientsService } from '../../../services/clients.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-clients-creator-field-nip',
  templateUrl: './clients-creator-field-nip.component.html',
  styleUrls: ['./clients-creator-field-nip.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ClientsCreatorFieldNipComponent {

  constructor(
    public parent: FormGroupDirective,
    private clientsService: ClientsService,
    private toastrService: ToastrService
  ) { }

  searchCompany() {
    if (!this.parent.form.get('nip').errors) {
      const value = this.parent.form.get('nip').value;
      const formBody = new FormData();

      formBody.append('nip', value);

      this.clientsService.getGus(formBody).subscribe(
        (response: any) => {
          if (response[0][0]?.ErrorMessagePl) {
            this.toastrService.error('Nie znaleziono firmy');
          } else {
            const { praw_adSiedzWojewodztwo_Nazwa, praw_nazwa, praw_adresEmail, praw_numerTelefonu } = response[0];

            this.parent.form.patchValue({
              ...this.parent.form.value,
              voivodeship: praw_adSiedzWojewodztwo_Nazwa,
              company_name: praw_nazwa,
              company_email: praw_adresEmail,
              company_phone: praw_numerTelefonu
            });
          }
        },
        (_) => {
          this.toastrService.error(`Nie znaleziono firmy`);
        }
      );
    }
  }
}
