import { Component, OnInit } from '@angular/core';
import {NavbarCalculationPickerComponent} from '../navbar/navbar-calculation-picker/navbar-calculation-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientsCreatorComponent} from '../clients/components/clients-creator/clients-creator.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  addClient(): void {
    this.dialog.open(ClientsCreatorComponent);
  }

  addCalculation(): void {
    this.dialog.open(NavbarCalculationPickerComponent);
  }
}
