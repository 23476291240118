import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LeasingComponent} from './leasing.component';
import {LeasingCreatorComponent} from './components/leasing-creator/leasing-creator.component';
import {LeasingCreatorStep1Component} from './components/leasing-creator/leasing-creator-step-1/leasing-creator-step-1.component';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LeasingCreatorCalculationComponent} from './components/leasing-creator/leasing-creator-calculation/leasing-creator-calculation.component';
import {AuthGuard} from '../auth/guards/auth.guard';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {LeasingCreatorScheduleComponent} from './components/leasing-creator/leasing-creator-calculation/leasing-creator-schedule/leasing-creator-schedule.component';
import {CalculationsModule} from '../calculations/calculations.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CalculationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: LeasingComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'leasing/create',
            component: LeasingCreatorComponent
          },
          {
            path: 'leasing/edit/:id',
            component: LeasingCreatorComponent
          },
          {
            path: 'leasing/edit/:id/:send',
            component: LeasingCreatorComponent
          },
        ]
      }
    ]),
    TranslateModule,
    SharedModule,
    CalculationsModule
  ],
  declarations: [
    LeasingComponent,
    LeasingCreatorComponent,
    LeasingCreatorStep1Component,
    LeasingCreatorCalculationComponent,
    LeasingCreatorScheduleComponent
  ]
})
export class LeasingModule {
}
