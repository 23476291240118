import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

const themes: string[] = ['deeppurple-amber', 'indigo-pink', 'pink-bluegrey', 'purple-green'];

@Component({
  selector: 'app-navbar-theme-picker',
  templateUrl: './navbar-theme-picker.component.html',
  styleUrls: ['./navbar-theme-picker.component.scss']
})
export class NavbarThemePickerComponent implements OnInit{
  themes = themes;
  selectedTheme = themes[0];

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.document.body.classList.add(themes[0]);
  }

  handleThemeChange(themeToSet: string): void {
    this.document.body.classList.replace(this.selectedTheme, themeToSet);
    this.selectedTheme = themeToSet;
  }
}
