import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {LeasingModule} from './modules/leasing/leasing.module';
import {CalculationsModule} from './modules/calculations/calculations.module';
import {ClientsModule} from './modules/clients/clients.module';
import {NavbarModule} from './modules/navbar/navbar.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CreditModule} from './modules/credit/credit.module';
import {AuthModule} from './modules/auth/auth.module';
import {httpTokenInterceptorProviders} from './interceptors/http-token.interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DashboardModule} from './modules/dashboard/dashboard.module';
import {ToastrModule} from 'ngx-toastr';
import {httpErrorInterceptor} from './interceptors/http-error.interceptor';
import {LoanModule} from './modules/loan/loan.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LeasingModule,
    CreditModule,
    CalculationsModule,
    AuthModule,
    ClientsModule,
    LoanModule,
    DashboardModule,
    NavbarModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NoopAnimationsModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      closeButton: true,
      positionClass: 'toast-bottom-right',
      timeOut: 2000,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    httpTokenInterceptorProviders,
    httpErrorInterceptor
  ],
})
export class AppModule {
}
