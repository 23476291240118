import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Calculation} from '../../../calculations/interfaces/Calculation';
import {ActivatedRoute, Router} from '@angular/router';
import {CalculationsService, calculationTypes} from '../../../calculations/services/calculations.service';
import {MatStepper} from '@angular/material/stepper';
import {downloadFile} from '../../../../../assets/helpers/downloadFile';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-loan-creator',
  templateUrl: './loan-creator.component.html',
  styleUrls: ['./loan-creator.component.scss']
})
export class LoanCreatorComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('stepper') stepper: MatStepper;
  step1: FormGroup;
  step2: FormGroup;
  step3: FormGroup;
  id: string | null = null;
  send: string | null = null;
  private unsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private calculationService: CalculationsService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.step1 = this.fb.group({});
    this.step2 = this.fb.group({});
    this.step3 = this.fb.group({});

    this.id = this.route.snapshot.paramMap.get('id');
    this.send = this.route.snapshot.paramMap.get('send');

    if (this.id) {
      this.getCalculation();
    }
  }

  ngAfterViewInit(): void {
    if (this.send) {
      setTimeout(() => {
        this.stepper.next();
        this.stepper.next();
      });
    }
  }

  getCalculation(): void {
    this.calculationService.getCalculation(calculationTypes.loan, this.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: Calculation) => {
          const {client_id, title, description, period, installment_type, commission_for_start_percent, commission_brokerage_percent, amount, wibor, margin} = response;

          const calculation = {
            title, description, period, installment_type, commission_for_start_percent,
            commission_brokerage_percent, amount, wibor, margin
          };

          Object.keys(calculation).forEach(key => calculation[key] === undefined && delete calculation[key]);

          this.step1.get('calculation').patchValue({
            ...this.step1.getRawValue().calculation,
            ...calculation,
          });

          this.step2.get('client.id').patchValue(client_id);
        },
        (_) => {
          this.toastrService.error(`Nie znaleziono klienta`);
        }
      );
  }

  goBack(stepper: MatStepper): void {
    stepper.previous();
  }

  goNext(stepper: MatStepper): void {
    const currentStep = `step${stepper.selectedIndex + 1}`;

    this[currentStep].markAllAsTouched();
    if (this[currentStep].valid) {
      stepper.next();
    }
  }

  createPdfCalculation(): void {
    const {calculation} = this.step1.getRawValue();
    this.step1.markAllAsTouched();

    if (this.step1.valid) {
      this.calculationService.createPdfCalculation(calculationTypes.loan, calculation)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          (response: string) => {
            downloadFile(response);
            this.toastrService.success('Pobrano kalkulację');
          },
          () => {
            this.toastrService.error('Błąd wewnętrzny');
          }
        );
    }
  }

  assignCalculationToClient(calculation): Observable<any> {
    const body = {calculation_id: calculation.id};
    const {client} = this.step2.getRawValue();

    if (calculation.id) {
      this.calculationService.assignCalculationToClient(body, client.id)
        .subscribe(
          () => this.toastrService.success('Przypisano kalkulację do klienta'),
          () => this.toastrService.error('Błąd wewnętrzny')
        );
    }
    return of(calculation);
  }

  sendCalculation(newCalculation, sendMail): Observable<any> {
    const {send} = this.step3.getRawValue();
    const {calculation} = this.step1.getRawValue();

    if (newCalculation.id && sendMail) {
      const body = {
        ...calculation,
        ...send
      };
      this.calculationService.sendCalculation(calculationTypes.loan, body, newCalculation.id)
        .subscribe(
          () => this.toastrService.success('Wysłano kalkulację'),
          () => this.toastrService.error('Błąd wewnętrzny')
        );
    }
    return of(newCalculation);
  }

  editCalculation(sendMail?: boolean): void {
    const {calculation} = this.step1.getRawValue();
    this.step1.markAllAsTouched();

    this.calculationService.editCalculation(calculationTypes.loan, calculation, this.id)
      .pipe(
        takeUntil(this.unsubscribe),
        map((response: Calculation) => response),
        switchMap((response: Calculation) => this.assignCalculationToClient(response)),
        switchMap((response: Calculation) => this.sendCalculation(response, sendMail))
      )
      .subscribe(
        () => {
          this.toastrService.success(sendMail ? 'Zapisano i wysłano kalkulację' : 'Zapisano kalkulację');
          this.router.navigateByUrl('calculations/list');
        },
        () => {
          this.toastrService.error('Błąd wewnętrzny');
        },
      );
  }

  saveCalculation(sendMail?: boolean): void {
    this.step1.markAllAsTouched();
    const {calculation} = this.step1.getRawValue();

    this.calculationService.saveCalculation(calculationTypes.loan, calculation)
      .pipe(
        takeUntil(this.unsubscribe),
        map((response: Calculation) => response),
        switchMap((response: Calculation) => this.assignCalculationToClient(response)),
        switchMap((response: Calculation) => this.sendCalculation(response, sendMail))
      )
      .subscribe(
        () => {
          this.toastrService.success(sendMail ? 'Zapisano i wysłano kalkulację' : 'Zapisano kalkulację');
          this.router.navigateByUrl('calculations/list');
        },
        () => {
          this.toastrService.error('Błąd wewnętrzny');
        },
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
