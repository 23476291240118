import {Component, Input} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {NavbarCalculationPickerComponent} from '../navbar-calculation-picker/navbar-calculation-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientsCreatorComponent} from '../../clients/components/clients-creator/clients-creator.component';

@Component({
  selector: 'app-navbar-drawer-list',
  templateUrl: './navbar-drawer-list.component.html',
  styleUrls: ['./navbar-drawer-list.component.scss']
})
export class NavbarDrawerListComponent {
  @Input() drawer: MatDrawer;

  constructor(
    private dialog: MatDialog
  ) {}

  openCalculationModal(): void {
    this.dialog.open(NavbarCalculationPickerComponent);
    this.closeDrawer();
  }

  openClientModal(): void {
    this.dialog.open(ClientsCreatorComponent, {
      data: {
        afterSubmitGoTo: 'clients/list'
      }
    });
    this.closeDrawer();
  }

  closeDrawer(): void {
    this.drawer.close();
  }
}
