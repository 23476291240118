import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ValidationService} from '../../../shared/services/validation.service';
import {ClientsService} from '../../services/clients.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Client} from '../../interfaces/Client';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {voivodeships} from '../../../../../assets/helpers/CONSTS';
import {ToastrService} from 'ngx-toastr';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

interface DialogData {
  afterSubmitGoTo: string;
}

@Component({
  selector: 'app-clients-creator',
  templateUrl: './clients-creator.component.html',
  styleUrls: ['./clients-creator.component.scss']
})
export class ClientsCreatorComponent implements OnInit, OnDestroy {
  form: FormGroup;
  voivodeships: string[] = Object.values(voivodeships).map(elem => elem);
  private unsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private clientsService: ClientsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<ClientsCreatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      nip: ['7251801126', [Validators.required, ValidationService.nipValidator]],
      company_name: ['nazwa firmy', [Validators.required]],
      accountancy_type: [''],
      accountant_phone: ['', [Validators.required]],
      company_email: ['eegrerg@fewf.pl', [Validators.required, Validators.email]],
      company_phone: ['123123123', [Validators.required]],
      accountant_email: ['', [Validators.required, Validators.email]],
      voivodeship: [''],
      agreement: [false],
      notes: ['']
    });

    const id = this.route.snapshot.paramMap.get('id');

    if (id) {
      this.getClient(id);
    }
  }

  getClient(clientId: string): void {
    this.clientsService.getClient(clientId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (response: Client) => {
          const {id, created_at, updated_at, ...client} = response;

          this.form.setValue(client);
        },
        (_) => {
          this.toastrService.error(`Nie znaleziono klienta`);
        }
      );
  }

  onSubmit(): void {
    if (this.form.valid) {
      const id = this.route.snapshot.paramMap.get('id');

      const bodyFormData = new FormData();

      const values = {
        ...this.form.value,
        agreement: this.form.value.agreement ? 1 : 0
      };

      Object.keys(values).forEach(key => bodyFormData.append(key, values[key]));

      const actionEndpoint = id ? this.clientsService.editClient(bodyFormData, id) : this.clientsService.saveClient(bodyFormData);

      actionEndpoint
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(
          () => {
            this.toastrService.success('Zapisano klienta');
            this.dialogRef.close();
            if (this.data?.afterSubmitGoTo) {
              this.router.navigateByUrl(this.data?.afterSubmitGoTo);
            }
          },
          (_) => this.toastrService.error('Błąd wewnętrzny')
        );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
