import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-credit-creator-schedule',
  templateUrl: './credit-creator-schedule.component.html',
  styleUrls: ['./credit-creator-schedule.component.scss']
})
export class CreditCreatorScheduleComponent implements OnInit {
  displayedColumns = [ 'month', 'installment', 'interest', 'capital', 'capital_sum', 'rest' ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
  }

}
