<div class="container">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-card>
          <mat-card-title class="mb-2">{{'sign in' | translate}}</mat-card-title>
          <mat-card-content>
            <mat-form-field class="w-100">
              <mat-label>{{'email' | translate}}*</mat-label>
              <input matInput type="email" placeholder="przykladowy@mail.com" formControlName="email">
              <mat-error><app-field-errors [errors]="form.get('email').errors"></app-field-errors></mat-error>
            </mat-form-field>
            <mat-form-field class="w-100">
              <mat-label>{{'password' | translate}}*</mat-label>
              <input matInput type="password" formControlName="password">
              <mat-error><app-field-errors [errors]="form.get('password').errors"></app-field-errors></mat-error>
            </mat-form-field>
          </mat-card-content>
          <mat-card-actions align="end">
            <button type="submit" color="primary" mat-raised-button>{{'sign in' | translate}}</button>
          </mat-card-actions>
          <p class="mat-caption text-right">{{'if you don`t have account' | translate}} <a class="mat-caption" routerLink="/auth/register">{{'sign up' | translate | lowercase}}</a></p>
        </mat-card>
      </form>
    </div>
  </div>
</div>
