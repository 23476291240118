import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Login, Register} from '../interfaces/auth';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLogged$: Observable<boolean>;
  private isLoggedSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
    this.isLoggedSubject = new BehaviorSubject<boolean>(!!localStorage.getItem('token'));
    this.isLogged$ = this.isLoggedSubject.asObservable();

  }

  getAuthHeader(): string {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  login(body: Login): Observable<object> {
    return this.http.post(`https://kalkulacje.codevocado.com/auth/login`, body).pipe(
      tap((response: {token: string}) => {
        localStorage.setItem('token', response.token);
        this.isLoggedSubject.next(true);
      })
    );
  }

  register(body: Register): Observable<object> {
    return this.http.post(`https://kalkulacje.codevocado.com/auth/signup`, body);
  }

  logout(): void {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
    this.isLoggedSubject.next(false);
  }

}
