<h2 mat-dialog-title>{{'monthly_schedule' | translate}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="py-3">
  <table mat-table [dataSource]="data" class="w-100">
    <ng-container matColumnDef="month">
      <th mat-header-cell *matHeaderCellDef> {{'month' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.month}} </td>
    </ng-container>

    <ng-container matColumnDef="installment_id">
      <th mat-header-cell *matHeaderCellDef> {{'installment_id' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.installment_id}} </td>
    </ng-container>

    <ng-container matColumnDef="installment">
      <th mat-header-cell *matHeaderCellDef> {{'installment' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.installment}} </td>
    </ng-container>

    <ng-container matColumnDef="capital">
      <th mat-header-cell *matHeaderCellDef> {{'capital' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.capital}} </td>
    </ng-container>


    <ng-container matColumnDef="capital_sum">
      <th mat-header-cell *matHeaderCellDef> {{'capital_sum' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.capital_sum}} </td>
    </ng-container>

    <ng-container matColumnDef="interest">
      <th mat-header-cell *matHeaderCellDef> {{'interest' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.interest}} </td>
    </ng-container>

    <ng-container matColumnDef="rest">
      <th mat-header-cell *matHeaderCellDef> {{'rest' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.rest}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{'close' | translate}}</button>
</mat-dialog-actions>
