<h3>{{'choose calculation type' | translate }}</h3>
<mat-nav-list>
  <a
    class="text-uppercase"
    (click)="closeDialog()"
    [routerLink]="'leasing/create'"
    mat-list-item>{{ 'leasing calculation' | translate }}</a>
  <a
    class="text-uppercase"
    (click)="closeDialog()"
    [routerLink]="'credit/create'"
    mat-list-item>{{ 'EU loan calculation' | translate }}</a>
  <a
    class="text-uppercase"
    (click)="closeDialog()"
    [routerLink]="'loan/create'"
    mat-list-item>{{ 'eco credit calculation' | translate }}</a>
</mat-nav-list>
