<mat-card class="d-flex justify-content-between align-items-center">
  <h2 class="m-0">{{'calculation list' | translate}}</h2>
  <aside>
    <button class="mr-2" [disabled]="selected.selected.length === 0" (click)="deleteClients()" mat-raised-button color="warn">{{'delete' | translate}}</button>
    <button (click)="addCalculation()" mat-raised-button color="primary">{{'add calculation' | translate}}</button>
  </aside>
</mat-card>

<div *ngIf="loading; else list" class="d-flex justify-content-center my-5">
  <mat-spinner class="d-flex justify-content-center" color="primary" ></mat-spinner>
</div>

<ng-template #list>
  <table mat-table [dataSource]="calculations" class="w-100">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="selectRow($event, row)"
          [checked]="selected.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> {{'title' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.title | translate}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{'type' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> {{'amount' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef> {{'period' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.period}} </td>
    </ng-container>

    <ng-container matColumnDef="installment">
      <th mat-header-cell *matHeaderCellDef> {{'installment' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.installment}} </td>
    </ng-container>

    <ng-container matColumnDef="detail">
      <th class="text-right" mat-header-cell *matHeaderCellDef></th>
      <td class="text-right" mat-cell *matCellDef="let element">
        <button (click)="showCalculationDetail(element)" mat-icon-button color="primary">
          <mat-icon>info</mat-icon>
        </button>
        <button [routerLink]="[ '/' + element.type + '/edit', element.id]" mat-icon-button color="primary">
          <mat-icon>create</mat-icon>
        </button>
        <button [routerLink]="[ '/' + element.type + '/edit', element.id, 'send']" mat-icon-button color="primary">
          <mat-icon>send</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>
<mat-paginator (page)="handlePaginatorChanges($event)" [length]="listMeta.length" [pageIndex]="0" [pageSize]="listMeta.pageSize"></mat-paginator>
