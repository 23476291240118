import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-calculations-creator-header',
  templateUrl: './calculations-creator-header.component.html',
  styleUrls: ['./calculations-creator-header.component.scss']
})
export class CalculationsCreatorHeaderComponent {
  @Input() title: 'Kreator leasing';
}
