import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, ControlContainer, FormGroupDirective, FormControl} from '@angular/forms';
import {ClientsService} from '../../../../clients/services/clients.service';
import {Client} from '../../../../clients/interfaces/Client';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ClientsCreatorComponent} from '../../../../clients/components/clients-creator/clients-creator.component';
import {MatDialog} from '@angular/material/dialog';
import {ClientsDetailComponent} from '../../../../clients/components/clients-detail/clients-detail.component';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-calculations-creator-step-2',
  templateUrl: './calculations-creator-step-2.component.html',
  styleUrls: ['./calculations-creator-step-2.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class CalculationsCreatorStep2Component implements OnInit, OnDestroy {
  displayedColumns: string[] = ['checked', 'company_name', 'actions'];
  clients = new MatTableDataSource<Client[]>([]);
  listMeta: PageEvent = {
    length: 0,
    pageSize: 0,
    pageIndex: 0,
  };
  loading = false;
  private unsubscribe = new Subject();

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder,
    private clientsService: ClientsService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.parent.form.addControl('client', this.fb.group({
      search: [''],
      id: [],
    }));

    this.getClients();

    this.parent.form.get('client.search').valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(text => {
        console.log(text);
      });
  }

  createClient(): void {
    const dialogRef = this.dialog.open(ClientsCreatorComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.getClients();
    });
  }

  getClients(): void {
    this.loading = true;
    this.clientsService.getClients().pipe(takeUntil(this.unsubscribe)).subscribe(
      (response: any) => {
        this.loading = false;
        this.clients = response.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showClientDetail(client: Client): void {
    this.dialog.open(ClientsDetailComponent, {
      data: client,
      minWidth: 600
    });
  }

  selectClient(id: number): void {
    this.parent.form.get('client.id').patchValue(id);
  }

  handlePaginatorChanges(event): void {
    this.listMeta = event;
    this.getClients();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
