import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators, ControlContainer, FormGroupDirective} from '@angular/forms';
import {installmentTypes, voivodeships, creditTitles} from '../../../../../../assets/helpers/CONSTS';

const formOptions = [
  {
    name: `${voivodeships.lowerSilesian}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 500000},
    marginOptions: [1.84, 2.84],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.lowerSilesian}${creditTitles.loanOZE}`,
    amountValidators: {min: 100000, max: 10000000},
    marginOptions: [0.5],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180]
  },
  {
    name: `${voivodeships.lowerSilesian}${creditTitles.energeticEfficiency}`,
    amountValidators: {min: 200000, max: 3000000},
    marginOptions: [0.5],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180]
  },
  {
    name: `${voivodeships.kuyavianPomeranian}${creditTitles.loanOZE}`,
    amountValidators: {min: 10000, max: 5000000},
    marginOptions: [0.5, 0.99],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120]
  },
  {
    name: `${voivodeships.lublin}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 250000},
    marginOptions: [1.84],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.lubusz}${creditTitles.developmental}`,
    amountValidators: {min: 30000, max: 400000},
    marginOptions: [0.94, 2.84],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.lodz}${creditTitles.forPropertyModernization}`,
    amountValidators: {min: 100000, max: 2000000},
    marginOptions: [0.5, 2.84],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120]
  },
  {
    name: `${voivodeships.lesserPoland}${creditTitles.developmental}`,
    amountValidators: {min: 200000, max: 3000000},
    marginOptions: [1.84, 2.84],
    periodOptions: [12, 24, 36, 48, 60, 72, 84]
  },
  {
    name: `${voivodeships.masovian}${creditTitles.developmentalAgglomeration}`,
    amountValidators: {min: 10000, max: 250000},
    marginOptions: [1.84],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.masovian}${creditTitles.developmentOutsideAgglomeration}`,
    amountValidators: {min: 10000, max: 500000},
    marginOptions: [1.84],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.opole}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 1000000},
    marginOptions: [1.84],
    periodOptions: [12, 24, 36, 48, 60, 72, 84]
  },
  {
    name: `${voivodeships.subcarpathian}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 500000},
    marginOptions: [1.35],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96]
  },
  {
    name: `${voivodeships.podlaskie}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 1000000},
    marginOptions: [0.5],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180]
  },
  {
    name: `${voivodeships.podlaskie}${creditTitles.thermModernization}`,
    amountValidators: {min: 10000, max: 2500000},
    marginOptions: [0],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180]
  },
  {
    name: `${voivodeships.pomeranian}${creditTitles.developmental}`,
    amountValidators: {min: 100000, max: 300000},
    marginOptions: [0.94],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.silesian}${creditTitles.developmental}`,
    amountValidators: {min: 200000, max: 3000000},
    marginOptions: [1.6],
    periodOptions: [12, 24, 36, 48, 60, 72, 84]
  },
  {
    name: `${voivodeships.holyCross}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 1000000},
    marginOptions: [0.9],
    periodOptions: [12, 24, 36, 48, 60]
  },
  {
    name: `${voivodeships.warmianMasurian}${creditTitles.developmental}`,
    amountValidators: {min: 10000, max: 1500000},
    marginOptions: [0.92, 2.84],
    periodOptions: [12, 24, 36, 48, 60, 72, 84, 96]
  },
  {
    name: `${voivodeships.greaterPoland}${creditTitles.developmental}`,
    amountValidators: {min: 50000, max: 500000},
    marginOptions: [1.84, 2.84],
    periodOptions: [12, 24, 36, 48, 60, 72, 84]
  },
  {
    name: `${voivodeships.westPomeranian}${creditTitles.investment}`,
    amountValidators: {min: 100000, max: 500000},
    marginOptions: [2.47],
    periodOptions: [12, 24, 36, 48, 60, 72, 84]
  }
]

const namesOptions = [{
  voivodeship: voivodeships.lowerSilesian,
  titles: [creditTitles.developmental, creditTitles.loanOZE, creditTitles.energeticEfficiency]
}, {
  voivodeship: voivodeships.kuyavianPomeranian,
  titles: [creditTitles.loanOZE]
}, {
  voivodeship: voivodeships.lublin,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.lubusz,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.lodz,
  titles: [creditTitles.forPropertyModernization]
}, {
  voivodeship: voivodeships.lesserPoland,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.masovian,
  titles: [creditTitles.developmentalAgglomeration, creditTitles.developmentOutsideAgglomeration]
}, {
  voivodeship: voivodeships.opole,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.subcarpathian,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.podlaskie,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.pomeranian,
  titles: [creditTitles.developmental, creditTitles.thermModernization]
}, {
  voivodeship: voivodeships.silesian,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.holyCross,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.warmianMasurian,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.greaterPoland,
  titles: [creditTitles.developmental]
}, {
  voivodeship: voivodeships.westPomeranian,
  titles: [creditTitles.investment]
}];

@Component({
  selector: 'app-loan-creator-step-1',
  templateUrl: './loan-creator-step-1.component.html',
  styleUrls: ['./loan-creator-step-1.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class LoanCreatorStep1Component implements OnInit {
  installmentTypes = installmentTypes;
  voivodeships = Object.values(voivodeships).map(elem => elem);
  titles = [creditTitles.developmental, creditTitles.loanOZE, creditTitles.energeticEfficiency];
  margins = [1.84, 2.84];
  periods = [60];

  constructor(
    public parent: FormGroupDirective,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.parent.form.addControl('calculation', this.fb.group({
      voivodeship: [voivodeships.lowerSilesian, {validators: [Validators.required], updateOn: 'blur'}],
      title: [namesOptions[0].titles[0], {validators: [Validators.required], updateOn: 'blur'}],
      description: ['To mój opis', {validators: [Validators.required], updateOn: 'blur'}],
      period: [60, {validators: [Validators.required], updateOn: 'blur'}],
      installment_type: ['diminishing', {validators: [Validators.required], updateOn: 'blur'}],
      commission_for_start_percent: [0, {validators: [Validators.required, Validators.max(10)], updateOn: 'blur'}],
      commission_brokerage_percent: [0, {validators: [Validators.required, Validators.max(10)], updateOn: 'blur'}],
      amount: [20000, {validators: [Validators.required, Validators.min(10000), Validators.max(100000000)], updateOn: 'blur'}],
      margin: [this.margins[0], {validators: [Validators.required], updateOn: 'blur'}],
      wibor: [{value: 0.00, disabled: true}, {validators: [Validators.required, Validators.min(0), Validators.max(100)], updateOn: 'blur'}],
    }));

    this.parent.form.get('calculation.title').valueChanges.subscribe(title => {

      const voivodeship = this.parent.form.get('calculation.voivodeship').value;

      const {amountValidators, periodOptions, marginOptions} = this.getFormOptions(voivodeship, title);

      this.periods = periodOptions;
      this.margins = marginOptions;

      this.parent.form.get('calculation.margin').patchValue(marginOptions[0]);
      this.parent.form.get('calculation.period').patchValue(periodOptions[0]);
      this.parent.form.get('calculation.amount').setValidators([Validators.min(amountValidators.min), Validators.max(amountValidators.max)]);
      this.parent.form.get('calculation.amount').patchValue(amountValidators.min);
    });

    this.parent.form.get('calculation.voivodeship').valueChanges.subscribe(voivodeship => {
      const titlesOptions = this.getTitles(voivodeship);
      const {amountValidators, periodOptions, marginOptions} = this.getFormOptions(voivodeship, titlesOptions[0]);

      this.periods = periodOptions;
      this.margins = marginOptions;
      this.titles = titlesOptions;

      this.parent.form.get('calculation.title').patchValue(titlesOptions[0]);
      this.parent.form.get('calculation.margin').patchValue(marginOptions[0]);
      this.parent.form.get('calculation.period').patchValue(periodOptions[0]);
      this.parent.form.get('calculation.amount').setValidators([Validators.min(amountValidators.min), Validators.max(amountValidators.max)]);
      this.parent.form.get('calculation.amount').patchValue(amountValidators.min);
    });
  }

  getTitles(voivodeship: string): string[] {
    return namesOptions.find(elem => elem.voivodeship === voivodeship).titles;
  }

  getFormOptions(voivodeship: string, title: string): any {
    return formOptions.find(elem => elem.name === `${voivodeship}${title}`);
  }
}
