import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClientsComponent } from './clients.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { ClientsCreatorComponent } from './components/clients-creator/clients-creator.component';
import { ClientsCreatorFieldNipComponent } from './components/clients-creator/clients-creator-field-nip/clients-creator-field-nip.component';
import { MaterialModule } from '../../material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsDetailComponent } from './components/clients-detail/clients-detail.component';
import {AuthGuard} from '../auth/guards/auth.guard';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    HttpClientModule,
    RouterModule.forChild([
      {
        path: '',
        component: ClientsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'clients/list',
            component: ClientsListComponent
          },
          {
            path: 'clients/create',
            component: ClientsCreatorComponent
          },
          {
            path: 'clients/edit/:id',
            component: ClientsCreatorComponent
          },
        ]
      }
    ]),
    SharedModule,
    TranslateModule
  ],
  declarations: [ClientsComponent, ClientsCreatorFieldNipComponent, ClientsListComponent, ClientsCreatorComponent, ClientsDetailComponent ]
})
export class ClientsModule { }

