import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../../auth/services/auth.service';

@Component({
  selector: 'app-navbar-auth-menu',
  templateUrl: './navbar-auth-menu.component.html',
  styleUrls: ['./navbar-auth-menu.component.scss']
})
export class NavbarAuthMenuComponent {
  @Input() isLogged = false;
  constructor(
    private authService: AuthService,
  ) { }

  logout(): void {
    this.authService.logout();
  }
}
