<section class="py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <header class="mb-5">
          <h1 class="mb-2">Witamy w kalkulacjach ofertowych</h1>
          <p>Aby rozpocząć kliknij któryś z poniższych kafelków</p>
        </header>
      </div>
      <div class="col-lg-6 mb-4">
        <mat-card >
          <mat-card-title>Dodaj kalkulację</mat-card-title>
          <mat-card-subtitle class="mt-3 mb-4">Wybór pomiędzy kalkulacją leasingową a pożyczką unijną.</mat-card-subtitle>
          <mat-card-actions align="end">
            <button (click)="addCalculation()" mat-raised-button color="primary">{{'add' | translate}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-lg-6 mb-4">
        <mat-card >
          <mat-card-title>Dodaj klienta</mat-card-title>
          <mat-card-subtitle class="mt-3 mb-4">Uzupełnij wszystkie podstawowe informacje dotyczące nowo dodanego klienta </mat-card-subtitle>
          <mat-card-actions align="end">
            <button (click)="addClient()" mat-raised-button color="primary">{{'add' | translate}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-lg-6 mb-4">
        <mat-card >
          <mat-card-title>Lista kalkulacji</mat-card-title>
          <mat-card-subtitle class="mt-3 mb-4">Zobacz listę dodanych kalkulacji </mat-card-subtitle>
          <mat-card-actions align="end">
            <a mat-raised-button routerLink="/calculations/list" color="primary">Pokaż</a>
          </mat-card-actions>
        </mat-card>
      </div>
      <div class="col-lg-6 mb-4">
        <mat-card >
          <mat-card-title>Lista klientów</mat-card-title>
          <mat-card-subtitle class="mt-3 mb-4">Zobacz listę klientów </mat-card-subtitle>
          <mat-card-actions align="end">
            <a mat-raised-button routerLink="/clients/list" color="primary">Pokaż</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</section>
