export const types = ['solar'];
export const periods = [48, 60, 72];
export const buyouts = [25, 15, 5];
export const installmentTypes = ['constant', 'diminishing'];
export const templates = ['template-basic', 'template-advanced'];
export const documents = ['empty', 'simplified-accounting-list', 'full-accountancy-list', 'farm-list', 'individual-client-list'];

export const creditTitles = {
  developmental: 'developmental',
  loanOZE: 'loanOZE',
  energeticEfficiency: 'energeticEfficiency',
  forPropertyModernization: 'forPropertyModernization',
  developmentalAgglomeration: 'developmentalAgglomeration',
  developmentOutsideAgglomeration: 'developmentOutsideAgglomeration',
  thermModernization: 'thermModernization',
  investment: 'investment'
};

export const voivodeships = {
  lowerSilesian:	'lowerSilesian',
  westPomeranian:	'westPomeranian',
  warmianMasurian:	'warmianMasurian',
  subcarpathian:	'subcarpathian',
  silesian:	'silesian',
  pomeranian:	'pomeranian',
  podlaskie:	'podlaskie',
  opole: 'opole',
  masovian:	'masovian',
  lubusz:	'lubusz',
  lublin:	'lublin',
  lodz:	'lodz',
  lesserPoland:	'lesserPoland',
  kuyavianPomeranian:	'kuyavianPomeranian',
  holyCross:	'holyCross',
  greaterPoland:	'greaterPoland'
};



