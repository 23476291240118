import {Component, OnInit} from '@angular/core';
import {CalculationsService} from '../../services/calculations.service';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {forkJoin} from 'rxjs';
import {NavbarCalculationPickerComponent} from '../../../navbar/navbar-calculation-picker/navbar-calculation-picker.component';
import {MatDialog} from '@angular/material/dialog';
import {Calculation} from '../../interfaces/Calculation';
import {CalculationsDetailComponent} from '../calculations-detail/calculations-detail.component';
import {ToastrService} from 'ngx-toastr';
import {PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-calculations-list',
  templateUrl: './calculations-list.component.html',
  styleUrls: ['./calculations-list.component.scss']
})
export class CalculationsListComponent implements OnInit {
  displayedColumns: string[] = ['select', 'title', 'type', 'amount', 'period', 'installment', 'detail'];
  calculations = new MatTableDataSource<Calculation[]>([]);
  listMeta: PageEvent = {
    length: 0,
    pageSize: 0,
    pageIndex: 0,
  };
  loading = false;
  selected = new SelectionModel<Calculation>(true, []);
  expandedElement = true;

  constructor(
    private calculationsService: CalculationsService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getCalculations();
  }

  selectRow($event, row): any | null {
    return $event ? this.selected.toggle(row) : null;
  }

  showCalculationDetail(calculation: Calculation): void {
    this.dialog.open(CalculationsDetailComponent, {
      data: calculation,
      minWidth: 600
    });
  }

  addCalculation(): void {
    this.dialog.open(NavbarCalculationPickerComponent);
  }

  deleteClients(): void {
    const ids = this.selected.selected.map(element => element.id);

    forkJoin(
      ...ids.map((id: number) => this.calculationsService.deleteCalculation(id))
    ).subscribe(
      () => {
        this.toastrService.success(`Usunięto elementów: ${ids.length}`);

        this.getCalculations();
      },
      (err) => {
        this.toastrService.error('Błąd wewnętrzny');
      }
    );
  }

  getCalculations(): void {
    this.loading = true;
    this.calculationsService.getCalculations(this.listMeta.pageIndex).subscribe(
      (response: any) => {
        this.loading = false;
        if (response.meta) {
          this.listMeta = {
            pageIndex: response.meta.current_page - 1,
            pageSize: response.meta.per_page,
            length: response.meta.total
          };
        }

        if (response.data) {
          const parseCalculations = response.data.map(elem => {
            const summaryParsed = JSON.parse(elem.summary);
            const installment = summaryParsed.monthly_installments?.net_amount || summaryParsed.installment.installment;

            return {
              ...elem,
              installment
            };
          });

          this.calculations = parseCalculations;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handlePaginatorChanges(event): void {
    this.listMeta = event;
    this.getCalculations();
  }
}
