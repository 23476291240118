import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Calculation} from '../../interfaces/Calculation';
import {Client} from '../../../clients/interfaces/Client';

@Component({
  selector: 'app-calculations-detail',
  templateUrl: './calculations-detail.component.html',
  styleUrls: ['./calculations-detail.component.scss']
})
export class CalculationsDetailComponent implements OnInit{
  calculation: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Calculation
  ) { }

  ngOnInit(): void {
    const { title, type, initial_fee, client, period, margin, wibor, amount, buyout, description, created_at, updated_at } = this.data;

    this.calculation = { title, type, initial_fee, period, margin, wibor, amount, buyout, description, created_at, updated_at };
  }
}
