<ng-container formGroupName="client">
  <h2>{{'client' | translate}}</h2>

  <mat-form-field class="w-100 my-3">
    <mat-label>{{'search client' | translate}}</mat-label>
    <input matInput type="text" placeholder="szukaj..." formControlName="search">
  </mat-form-field>

  <div *ngIf="loading; else list" class="d-flex justify-content-center my-5">
    <mat-spinner class="d-flex justify-content-center" color="primary" ></mat-spinner>
  </div>

  <ng-template #list>
    <table mat-table [dataSource]="clients">
      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="selectClient(element.id)"
            [checked]="parent.form.value.client.id === element.id">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="company_name">
        <th mat-header-cell *matHeaderCellDef> {{'company_name' | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{'detail' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <button (click)="showClientDetail(element)" mat-icon-button color="primary">
            <mat-icon>info</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </ng-template>
  <mat-paginator (page)="handlePaginatorChanges($event)" [length]="listMeta.length" [pageIndex]="0" [pageSize]="listMeta.pageSize"></mat-paginator>

  <div class="d-flex justify-content-end mt-4">
    <button (click)="createClient()" mat-raised-button color="primary">{{'create client' | translate}}</button>
  </div>
</ng-container>
