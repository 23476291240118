import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CalculationsComponent} from './calculations.component';
import {CalculationsListComponent} from './components/calculations-list/calculations-list.component';
import {MaterialModule} from '../../material.module';
import {HttpClientModule} from '@angular/common/http';
import {CalculationsDetailComponent} from './components/calculations-detail/calculations-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthGuard} from '../auth/guards/auth.guard';
import {TranslateModule} from '@ngx-translate/core';
import {CalculationsCreatorStep2Component} from './components/calculations-creator/calculations-creator-step-2/calculations-creator-step-2.component';
import {CalculationsCreatorStep3Component} from './components/calculations-creator/calculations-creator-step-3/calculations-creator-step-3.component';
import {SharedModule} from '../shared/shared.module';
import {CalculationsCreatorFooterComponent} from './components/calculations-creator/calculations-creator-footer/calculations-creator-footer.component';
import {CalculationsCreatorHeaderComponent} from './components/calculations-creator/calculations-creator-header/calculations-creator-header.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: CalculationsComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'calculations/list',
            component: CalculationsListComponent
          },
        ]
      }
    ]),
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [
    CalculationsCreatorStep2Component,
    CalculationsCreatorStep3Component,
    CalculationsCreatorFooterComponent,
    CalculationsCreatorHeaderComponent
  ],
  declarations: [
    CalculationsComponent,
    CalculationsCreatorFooterComponent,
    CalculationsCreatorHeaderComponent,
    CalculationsListComponent,
    CalculationsDetailComponent,
    CalculationsCreatorStep2Component,
    CalculationsCreatorStep3Component
  ]
})
export class CalculationsModule {
}

