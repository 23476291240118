import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {ValidationService} from '../../../shared/services/validation.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.scss']
})
export class AuthRegisterComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required, ValidationService.matchValues('password')]],
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const body = this.form.getRawValue();

      this.authService.register(body).subscribe(
        (response) => {
          this.toastrService.success('Sprawdź maila aby aktywować konto');
        },
        (err) => {
          this.toastrService.error('Błąd wewnętrzny');
        }
      );
    }
  }
}
