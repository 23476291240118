import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../modules/auth/services/auth.service';
import {Observable} from 'rxjs';

@Injectable()
class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authHeader = this.authService.getAuthHeader();

    const authReq = req.clone({headers: req.headers.set('Authorization', authHeader)});
    return next.handle(authReq);
  }
}

export const httpTokenInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true},
];
