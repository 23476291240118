import {AfterContentInit, AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CalculationsService, calculationTypes} from '../../../../calculations/services/calculations.service';
import {debounce, debounceTime, delay, distinctUntilChanged, pairwise, startWith, take, takeUntil, timeout} from 'rxjs/operators';
import {Subject, timer} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {LeasingCreatorScheduleComponent} from '../../../../leasing/components/leasing-creator/leasing-creator-calculation/leasing-creator-schedule/leasing-creator-schedule.component';
import {CreditCreatorScheduleComponent} from './credit-creator-schedule/credit-creator-schedule.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-credit-creator-calculation',
  templateUrl: './credit-creator-calculation.component.html',
  styleUrls: ['./credit-creator-calculation.component.scss'],
})
export class CreditCreatorCalculationComponent implements OnInit, OnDestroy {
  @Input() step1: FormGroup;
  displayedColumns: string[] = ['type', 'value'];
  summary: any[];
  schedule: any[];
  private unsubscribe = new Subject();

  constructor(
    private calculationService: CalculationsService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.calculate();

    this.step1.controls.calculation.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(300),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]: [any, any]) => {
        const toDelete = ['description', 'installment_type'];

        toDelete.forEach(elem => {
          delete prev[elem];
          delete next[elem];
        });

        if (JSON.stringify(prev) !== JSON.stringify(next)) {
          this.calculate();
        }
      });
  }

  calculate(): void {
    if (this.step1.controls.calculation.valid) {
      const body = this.step1.getRawValue().calculation;

      this.calculationService.calculate(calculationTypes.credit, body)
        .subscribe(
        (response: any) => {
          if (response.month_table) {
            this.schedule = JSON.parse(response.month_table);
          }

          if (response.summary) {
            const {period, commission_for_start_percent, commission_brokerage_percent, amount, summary} = response;
            const {margin, total_amount, total_amount_and_comissions, sum_of_interest, installment, average_annual_cost, average_annual_cost_commisions} = JSON.parse(summary);

            const summaryPrepared = Object.entries({
              period,
              commission_for_start_percent,
              commission_brokerage_percent,
              amount,
              margin,
              total_amount,
              total_amount_and_comissions,
              sum_of_interest,
              installment,
              average_annual_cost,
              average_annual_cost_commisions
            }).map(elem => ({
              type: elem[0],
              value: elem[1]
            }));

            this.summary = summaryPrepared;
          }
        },
        () => {
          this.toastrService.error('Błąd wewnętrzny');
        }
      );
    }
  }

  showSchedule(): void {
    this.dialog.open(CreditCreatorScheduleComponent, {
      data: this.schedule,
      minWidth: 600
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
