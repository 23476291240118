import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html'
})
export class CreditComponent {

  constructor() { }
}
