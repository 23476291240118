import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

export const calculationTypes = {
  leasing: 'leasing',
  credit: 'credit',
  loan: 'loan'
};

@Injectable({
  providedIn: 'root'
})
export class CalculationsService {
  constructor(private http: HttpClient) { }

  calculate (type: string, body): Observable<any> {
    return this.http.post(`https://kalkulacje.codevocado.com/${type}`, body);
  }

  getCalculations(currentPage): Observable<any> {
    return this.http.get(`https://kalkulacje.codevocado.com/calculations?page=${currentPage + 1}`);
  }

  getCalculation(type, id): Observable<any> {
    return this.http.get(`https://kalkulacje.codevocado.com/${type}/${id}`);
  }

  deleteCalculation (id) {
    return this.http.delete(`https://kalkulacje.codevocado.com/calculations/${id}`);
  }

  editCalculation(type, body, id) {
    return this.http.post(`https://kalkulacje.codevocado.com/${type}/${id}`, body);
  }

  saveCalculation(type, body) {
    return this.http.post(`https://kalkulacje.codevocado.com/${type}/save`, body);
  }

  assignCalculationToClient(body, clientId): Observable<any> {
    return this.http.post(`https://kalkulacje.codevocado.com/clients/${clientId}/calculations`, body);
  }

  sendCalculation(type, body, calculationId) {
    return this.http.post(`https://kalkulacje.codevocado.com/${type}/${calculationId}/send`, body);
  }

  createPdfCalculation(type, body) {
    return this.http.post(`https://kalkulacje.codevocado.com/${type}/pdf`, body);
  }
}
