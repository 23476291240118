<mat-card class="d-flex justify-content-between align-items-center">
  <h2 class="m-0">Lista klientów</h2>
  <aside>
    <button class="mr-2" [disabled]="selected.selected.length === 0" (click)="deleteClients()" mat-raised-button color="warn">Usuń</button>
    <button (click)="createClient()" mat-raised-button color="primary">Stwórz klienta</button>
  </aside>
</mat-card>

<div *ngIf="loading; else list" class="d-flex justify-content-center my-5">
  <mat-spinner class="d-flex justify-content-center" color="primary"></mat-spinner>
</div>

<ng-template #list>
  <table mat-table [dataSource]="clients" class="w-100">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="selectRow($event, row)"
          [checked]="selected.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="company_name">
      <th mat-header-cell *matHeaderCellDef> {{'company_name' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
    </ng-container>

    <ng-container matColumnDef="company_email">
      <th mat-header-cell *matHeaderCellDef> {{'company_email' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.company_email}} </td>
    </ng-container>

    <ng-container matColumnDef="company_phone">
      <th mat-header-cell *matHeaderCellDef> {{'company_phone' | translate}} </th>
      <td mat-cell *matCellDef="let element"> {{element.company_phone}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th class="text-right" mat-header-cell *matHeaderCellDef></th>
      <td class="text-right" mat-cell *matCellDef="let element">
        <button (click)="showClientDetail(element)" mat-icon-button color="primary">
          <mat-icon>info</mat-icon>
        </button>
        <button [routerLink]="['/clients/edit', element.id]" mat-icon-button color="primary" aria-label="Example icon button with a home icon">
          <mat-icon>create</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</ng-template>

<mat-paginator (page)="handlePaginatorChanges($event)" [length]="listMeta.length" [pageIndex]="0" [pageSize]="listMeta.pageSize"></mat-paginator>
