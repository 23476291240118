import {Component, Input, OnInit} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AuthService} from '../../auth/services/auth.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-navbar-toolbar',
  templateUrl: './navbar-toolbar.component.html',
  styleUrls: ['./navbar-toolbar.component.scss']
})
export class NavbarToolbarComponent implements OnInit {
  @Input() drawer: MatDrawer;
  isLogged: boolean;

  constructor(private authService: AuthService) {
    authService.isLogged$.subscribe((newBool: boolean) => {
      this.isLogged = newBool;
    });
  }

  ngOnInit(): void {
  }
}
