<mat-nav-list>
  <a class="text-uppercase px-4" (click)="closeDrawer()" routerLink="/dashboard"  mat-list-item>{{'dashboard' | translate}}</a>

  <mat-divider></mat-divider>
  <a class="text-uppercase px-4" (click)="openCalculationModal()" mat-list-item>{{'add calculation' | translate}}</a>
  <a class="text-uppercase px-4" (click)="openClientModal()" mat-list-item>{{'add client' | translate}}</a>
  <mat-divider></mat-divider>
  <a class="text-uppercase px-4" (click)="closeDrawer()" routerLink="/calculations/list" mat-list-item>{{'calculation list' | translate}}</a>
  <a class="text-uppercase px-4" (click)="closeDrawer()" routerLink="/clients/list" mat-list-item>{{'client list' | translate}}</a>
</mat-nav-list>
