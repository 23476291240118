import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

interface Link {
  name: string;
  link: string;
}

@Component({
  selector: 'app-navbar-calculation-picker',
  templateUrl: './navbar-calculation-picker.component.html',
  styleUrls: ['./navbar-calculation-picker.component.scss']
})
export class NavbarCalculationPickerComponent {
  constructor(
    public dialogRef: MatDialogRef<NavbarCalculationPickerComponent>,
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
