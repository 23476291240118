<button [matMenuTriggerFor]="menu" mat-icon-button>
  <mat-icon>person</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <ng-container *ngIf="isLogged; else notLogged">
    <button routerLink="auth/profile" mat-menu-item>{{'profile' | translate}}</button>
    <button  (click)="logout()" mat-menu-item>{{'sign out' | translate}}</button>
  </ng-container>
  <ng-template #notLogged>
    <button routerLink="auth/login" mat-menu-item>{{'sign in' | translate}}</button>
    <button routerLink="auth/register" mat-menu-item>{{'sign up' | translate}}</button>
  </ng-template>
</mat-menu>
