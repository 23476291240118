import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CreditCreatorStep1Component} from './components/credit-creator/credit-creator-step-1/credit-creator-step-1.component';
import {CreditCreatorComponent} from './components/credit-creator/credit-creator.component';
import {CreditComponent} from './credit.component';
import {CreditCreatorCalculationComponent} from './components/credit-creator/credit-creator-calculation/credit-creator-calculation.component';
import {CalculationsCreatorHeaderComponent} from '../calculations/components/calculations-creator/calculations-creator-header/calculations-creator-header.component';
import {AuthGuard} from '../auth/guards/auth.guard';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import { CreditCreatorScheduleComponent } from './components/credit-creator/credit-creator-calculation/credit-creator-schedule/credit-creator-schedule.component';
import {CalculationsModule} from '../calculations/calculations.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    CalculationsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: CreditComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'credit/create',
            component: CreditCreatorComponent
          },
          {
            path: 'credit/edit/:id',
            component: CreditCreatorComponent
          },
          {
            path: 'credit/edit/:id/:send',
            component: CreditCreatorComponent
          },
        ]
      }
    ]),
    TranslateModule,
    SharedModule
  ],
  declarations: [CreditComponent, CreditCreatorComponent, CreditCreatorStep1Component, CreditCreatorCalculationComponent, CreditCreatorScheduleComponent]
})
export class CreditModule { }
