import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) {
  }

  getClients(): Observable<any>{
    return this.http.get('https://kalkulacje.codevocado.com/clients');
  }

  getClient(id): Observable<object> {
    return this.http.get(`https://kalkulacje.codevocado.com/clients/${id}`);
  }

  getGus(body): Observable<any> {
    return this.http.post('https://kalkulacje.codevocado.com/gus', body);
  }

  deleteClient(id): Observable<any> {
    return this.http.delete(`https://kalkulacje.codevocado.com/clients/${id}`);
  }

  editClient(body, id): Observable<any> {
    return this.http.post(`https://kalkulacje.codevocado.com/clients/${id}`, body);
  }

  saveClient(body): Observable<any> {
    return this.http.post('https://kalkulacje.codevocado.com/clients', body);
  }
}
