import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

const isValidNip = nip => {
  if(typeof nip !== 'string') {
      return false;
  }

  nip = nip.replace(/[\ \-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  // tslint:disable-next-line: radix
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
      // tslint:disable-next-line: radix
      sum += (parseInt(nip.substr(i, 1)) * weight[i]);
  }

  return sum % 11 === controlNumber;
};

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

constructor() { }

  static nipValidator(control: AbstractControl): ValidationErrors | null {
    const isValid = isValidNip(control.value);

    return !isValid ? { nip: true } : null;
  }

  static phoneValidator(control: AbstractControl): ValidationErrors | null {
    const regexp = new RegExp('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s./0-9]*$');
    const isPhoneNumber = regexp.test(control.value);
    const atLeast7Digits = control.value.replace(/\s+/g, '').length >= 7;
    return !control.value
      ? null
      : !isPhoneNumber || !atLeast7Digits
      ? { phone: true }
      : null;
  }

  static matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }
}
