import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CalculationsService, calculationTypes} from '../../../../calculations/services/calculations.service';
import {Subject} from 'rxjs';
import {debounceTime, delay, distinctUntilChanged, pairwise, startWith, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {CalculationsDetailComponent} from '../../../../calculations/components/calculations-detail/calculations-detail.component';
import {MatDialog} from '@angular/material/dialog';
import {LeasingCreatorScheduleComponent} from './leasing-creator-schedule/leasing-creator-schedule.component';

interface SummaryElement {
  net_amount: number;
  percent: number;
  quantity: number;
  type: string;
  vat_tax: number;
}

@Component({
  selector: 'app-leasing-creator-calculation',
  templateUrl: './leasing-creator-calculation.component.html',
  styleUrls: ['./leasing-creator-calculation.component.scss'],
})
export class LeasingCreatorCalculationComponent implements OnInit, OnDestroy {
  @Input() step1: FormGroup;
  displayedColumns: string[] = ['type', 'net_amount', 'percent', 'quantity', 'vat_tax'];
  summary: SummaryElement[];
  schedule: [];
  private unsubscribe = new Subject();

  constructor(
    private calculationService: CalculationsService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.calculate();

    this.step1.controls.calculation.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(300),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        const toDelete = ['title', 'description'];

        toDelete.forEach(elem => {
          delete prev[elem];
          delete next[elem];
        });

        if (JSON.stringify(prev) !== JSON.stringify(next)) {
          this.calculate();
        }
      });
  }

  calculate(): void {
    if (this.step1.controls.calculation.valid) {
      const body = this.step1.getRawValue().calculation;
      this.calculationService.calculate(calculationTypes.leasing, body)
        .subscribe(
          (response: any) => {
            if (response.month_table) {
              this.schedule = JSON.parse(response.month_table);
            }

            if (response.summary) {
              const summaryData = JSON.parse(response.summary);
              this.summary = Object.entries(summaryData).map(elem => ({
                type: elem[0],
                // @ts-ignore
                ...elem[1]
              }));
            }
          },
          () => {
            this.toastrService.error('Błąd wewnętrzny');
          }
        );
    }
  }

  showSchedule(): void {
    this.dialog.open(LeasingCreatorScheduleComponent, {
      data: this.schedule,
      minWidth: 600
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
