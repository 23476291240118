import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoanCreatorStep1Component} from './components/loan-creator/loan-creator-step-1/loan-creator-step-1.component';
import {LoanCreatorComponent} from './components/loan-creator/loan-creator.component';
import {LoanComponent} from './loan.component';
import {LoanCreatorCalculationComponent} from './components/loan-creator/loan-creator-calculation/loan-creator-calculation.component';
import {AuthGuard} from '../auth/guards/auth.guard';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {LoanCreatorScheduleComponent} from './components/loan-creator/loan-creator-calculation/loan-creator-schedule/loan-creator-schedule.component';
import {CalculationsModule} from '../calculations/calculations.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    CalculationsModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: LoanComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'loan/create',
            component: LoanCreatorComponent
          },
          {
            path: 'loan/edit/:id',
            component: LoanCreatorComponent
          },
          {
            path: 'loan/edit/:id/:send',
            component: LoanCreatorComponent
          },
        ]
      }
    ]),
    TranslateModule,
    SharedModule
  ],
  declarations: [
    LoanComponent,
    LoanCreatorComponent,
    LoanCreatorStep1Component,
    LoanCreatorCalculationComponent,
    LoanCreatorScheduleComponent
  ]
})
export class LoanModule {
}
