<ng-container formGroupName="send">
  <h2>{{'send' | translate}}</h2>
  <div class="container-fluid p-0 m-0">
    <div class="row">
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'template' | translate}}</mat-label>
          <mat-select formControlName="template">
            <mat-option *ngFor="let template of templates" [value]="template">
              {{template | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6">
        <mat-form-field class="w-100 my-3" appearance="fill">
          <mat-label>{{'document' | translate}}</mat-label>
          <mat-select formControlName="document">
            <mat-option *ngFor="let document of documents" [value]="document">
              {{document | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-12">
        <div class="w-100 my-3 d-flex align-items-end">
          <mat-checkbox
            color="primary"
            formControlName="realizationSend">
            {{'sending for execution (REPORT CUSTOMER)' | translate}}
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div class="row mt-2" formArrayName="emails">
      <div class="col-12">
        <mat-card-title>{{'add recipients' | translate}}:</mat-card-title>
      </div>
      <div class="col-6 d-flex justify-content-between align-items-center" *ngFor="let item of emails.controls; let i = index;">
        <mat-form-field class="w-100 my-3">
          <mat-label>{{'email' | translate}}</mat-label>
          <input matInput type="text" placeholder="adres email" [formControlName]="i">
          <mat-error><app-field-errors [errors]="emails.controls[i].errors"></app-field-errors></mat-error>
        </mat-form-field>
        <button (click)="removeContact(i)" mat-icon-button color="warn">
          <mat-icon>remove_circle</mat-icon>
        </button>
      </div>
      <div class="col-12">
        <mat-error><app-field-errors [errors]="parent.form.get('send.emails').errors"></app-field-errors></mat-error>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <button mat-raised-button color="primary" type="button" (click)="createContact()">{{'add recipient' | translate}}</button>
      </div>
    </div>
  </div>
</ng-container>
